/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: ProximaNovaThin;
    src: url(assets/fonts/ProximaNovaThin.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaRegular;
    src: url(assets/fonts/ProximaNovaRegular.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaBold;
    src: url(assets/fonts/ProximaNovaBold.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaBlack;
    src: url(assets/fonts/ProximaNovaBlack.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaAltThin;
    src: url(assets/fonts/ProximaNovaAltThin.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaAltLight;
    src: url(assets/fonts/ProximaNovaAltLight.otf) format("opentype");
}

@font-face {
    font-family: ProximaNovaAltBold;
    src: url(assets/fonts/ProximaNovaAltBold.otf) format("opentype");
}

.proximaNovaThin {
  font-family: ProximaNovaAltThin;
}

.proximaNovaRegular {
  font-family: ProximaNovaRegular;
}

.proximaNovaBold {
  font-family: ProximaNovaAltBold;
}

.gradientBlueBackground {
    background: linear-gradient(to left, #28a5f5, #1e87f0) !important;
}

.whiteText {
    color: #fff !important;
}
.navbarLink {
    font-weight: 200;
    letter-spacing: 2px;
}

.fw200 {
    font-weight: 200;
}

.fw500 {
    font-weight: 500;
}

.uk-padding-xsmall {
    padding: 5px !important;
}

.uk-dotted-border-left {
    border-left: 1px dotted #e5e5e5;
}

.uk-dotted-border-right {
    border-right: 1px dotted #e5e5e5;
}

.uk-dotted-border-bottom {
    border-bottom: 1px dotted #e5e5e5;
}

// Styling for the vis js timeline library
.vis-timeline {
  border: none !important;
}

.timeline-patent-identifier {
  border-radius: 1000px !important;
  background: #ffffff;
  color: #e5e5e5;
  border-color: #e5e5e5;
  cursor: pointer;
}

.timeline-label-identifier {
  border-radius: 1000px !important;
  background: #edfbf6;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.float-right {
  float: right;
}

.overflow-hidden {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.dark-text {
  color: #666 !important;
}

.dark-border {
  border-color: #666 !important;
}
